.bglog{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-size: contain;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-content: center;
}
.bgLogin{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/falcon-image.appspot.com/o/threebulding.png?alt=media&token=e3e38611-d26f-4c32-8720-f1c6349bc3d4");
}
.bgSignup{
    background-image: url("https://firebasestorage.googleapis.com/v0/b/falcon-image.appspot.com/o/threebulding.png?alt=media&token=e3e38611-d26f-4c32-8720-f1c6349bc3d4");

}
.wResponsive{
    max-width: 75%;
}
.eyesItem{
    cursor: pointer;
    position: absolute;
    bottom: .75rem;
    right: .5rem;
    z-index: 3;

}

@media (min-width: 768px) {
   
.wResponsive{
    max-width: 40%;
}
  }